import React, { useState, useEffect, useContext, createContext } from 'react';
import { useParams } from 'react-router-dom';
import Header from './components/header/Header';
import Body from './components/body/Body';
import Snackbar from './components/snackbar/Snackbar';
import Pulsating from './components/pulsating/Pulsating';
import { useStaffContext } from "./context/StaffContext";

export const GeolocationContext = createContext({ latitude: 0, longitude: 0 });

const App = () => {
    const { tableId, hospitalityId } = useParams();
    const [language, setLanguage] = useState<string>('');
    const { latitude, longitude } = useContext(GeolocationContext);
    const { callStaff, response,setResponse, error } = useStaffContext();
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showSnackbarError, setShowSnackbarError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');


    const getErrorMessage = (errorCode: any, language: string) => {
        switch (errorCode) {
            case 400:
                return language === "hr-HR" ? "Ne možete pozvati s te lokacije!" : "You can't call from that location!";
            case 403:
                return language === "hr-HR" ? "Konobar već pozvan!" : "Waiter allready called!";
            case 409:
                return language === "hr-HR" ? "Nema konobara na mreži!" : "There is no waiters on network!";
            default:
                return language === "hr-HR" ? "Nepoznata greška!" : "Unknown error!";
        }
    };
    useEffect(() => {
        if (response === 201) {
            setShowSnackbar(true);
            const timer = setTimeout(() => {
                setShowSnackbar(false);
                setResponse(null);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [response]);
    useEffect(() => {
        const errorCode = Number(error);
        if ([400, 403, 409].includes(errorCode)) {
            setShowSnackbarError(true);
            setErrorMessage(getErrorMessage(errorCode, language));
            const timer = setTimeout(() => {
                setShowSnackbarError(false);
                setErrorMessage('');
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [error, language]);

    useEffect(() => {
        const userLanguage = navigator.language;
        setLanguage(userLanguage);
        console.log(userLanguage,"evo jezik");
    }, []);
    console.log(error,"evo me")

    return (
        <div>
            <Header title="eKonobar.hr" />
            <Body>
                <Pulsating
                    language={language}
                    callStaff={callStaff}
                    tableId={tableId}
                    hospitalityId={hospitalityId}
                    latitude={latitude}
                    longitude={longitude}
                />
            </Body>
            {showSnackbar && (
                <Snackbar
                    message={language === "hr-HR" ? "Konobar je pozvan!" : "Waiter has been called" }
                    type="green"
                />
            )}
            {showSnackbarError && (
                <Snackbar
                    message={errorMessage}
                    type="red"
                />
            )}
        </div>
    );
};

export default App;
