import React from 'react';
import styles from './pulsating.module.css';

interface PulsatingProps {
    callStaff: (tableId: string, hospitalityId: string, latitude: number, longitude: number) => void;
    tableId?: string;
    hospitalityId?: string;
    language: string;
    latitude: number;
    longitude: number;
}

const Pulsating: React.FC<PulsatingProps> = ({ language, callStaff, tableId, hospitalityId, latitude, longitude }) => {
    const titleText: string[] = language === "hr-HR" ? ["POZOVI"] : ["CALL", "WAITER"];
    const handleCallStaff = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        console.log('handleCallStaff clicked');
        console.log('tableId:', tableId);
        console.log('hospitalityId:', hospitalityId);
        console.log('latitude:', latitude);
        console.log('longitude:', longitude);

        if (!tableId || !hospitalityId) {
            console.error('Missing tableId or hospitalityId');
            return;
        }

        callStaff(tableId, hospitalityId, latitude, longitude);
    };

    return (
        <div onClick={(e)=>handleCallStaff(e)} className={styles.pulseContainer}>
            <div className={styles.emitter}>
                <p className={styles.pulseContainerP}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="82" height="110" fill="none">
                        <path fill="#333"
                              d="M13.667 32.686c0-4.462.912-8.58 2.735-12.356 1.825-3.775 4.287-6.962 7.386-9.562l2.694 3.533c-2.596 2.18-4.652 4.854-6.17 8.02-1.518 3.166-2.276 6.621-2.276 10.365h-4.37Zm50.297 0c0-3.744-.758-7.204-2.276-10.38-1.518-3.175-3.574-5.854-6.17-8.035l2.694-3.503c3.1 2.6 5.561 5.787 7.386 9.562 1.823 3.776 2.735 7.894 2.735 12.356h-4.368Zm-44.808 27.15v-4.539h5.265V32.715c0-4.07 1.209-7.669 3.627-10.796 2.417-3.127 5.521-5.127 9.311-6v-1.886c0-1.048.355-1.94 1.065-2.677.71-.737 1.568-1.106 2.576-1.106s1.867.369 2.576 1.106c.71.737 1.065 1.63 1.065 2.677v1.885c3.79.874 6.894 2.874 9.311 6.001 2.418 3.127 3.627 6.726 3.627 10.796v22.582h5.266v4.539h-43.69ZM41 68.333c-1.452 0-2.693-.534-3.721-1.603-1.03-1.069-1.544-2.358-1.544-3.868h10.53c0 1.51-.514 2.8-1.543 3.868-1.029 1.069-2.27 1.603-3.722 1.603ZM28.79 55.297h24.42V32.715c0-3.504-1.191-6.494-3.575-8.971-2.385-2.478-5.263-3.716-8.635-3.716s-6.25 1.238-8.634 3.716c-2.384 2.477-3.576 5.467-3.576 8.97v22.583Z"/>
                        <text x="50%" y="80%" fill="black" fontSize="16" fontFamily="Arial" fontWeight="600"
                              letterSpacing="2" textAnchor="middle">
                            {titleText.map((line, index) => (
                                <tspan key={index} x="50%" dy={index === 0 ? 0 : "1.2em"}>
                                    {line}
                                </tspan>
                            ))}
                        </text>
                    </svg>
                </p>
            </div>
            <div className={styles.pulse}></div>
            <div className={`${styles.pulse} ${styles.pulseDelay}`}></div>
        </div>
    );
};

export default Pulsating;
