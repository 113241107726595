import React, { createContext, useContext, useState, ReactNode } from 'react';
import axios from 'axios';

interface StaffContextProps {
    callStaff: (tableId: string | undefined, hospitalityId: string | undefined, latitude: number, longitude: number) => Promise<void>;
    setResponse: any;
    setError: any;
    response: any;
    error: string | null;
}

const StaffContext = createContext<StaffContextProps | undefined>(undefined);

export const StaffProvider = ({ children }: { children: ReactNode }) => {
    const [response, setResponse] = useState<any>(null);
    const [error, setError] = useState<string | null>(null);

    const callStaff = async (tableId: string | undefined, hospitalityId: string | undefined, latitude: number, longitude: number): Promise<void> => {
        console.log('callStaff called with geolocation:', { latitude, longitude });
        if (!tableId || !hospitalityId) {
            setError('Table ID and Hospitality ID must be provided.');
            console.log('Table ID and Hospitality ID must be provided.');
            return;
        }

        try {
            console.log('Making API call with data:', {
                tableId,
                hospitalityId,
                latitude,
                longitude
            });
            const res = await axios.post('https://api-dev.ekonobar.hr/public/v1/staffs/call', {
                data: {
                    type: 'callStaff',
                    attributes: {
                        latitude,
                        longitude,
                    },
                    relationships: {
                        table: {
                            data: {
                                id: tableId,
                                type: 'table',
                            },
                        },
                        hospitalityEstablishment: {
                            data: {
                                id: hospitalityId,
                                type: 'hospitalityEstablishment',
                            },
                        },
                    },
                },
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log(res.status,"res status")
            setResponse(res.status);
            setError(null);
        } catch (err) {
            if (axios.isAxiosError(err)) {
                // @ts-ignore
                setError(err.response.status);
            } else {
                setError('An unexpected error occurred');
            }
            setResponse(null);
        }
    };

    return (
        <StaffContext.Provider value={{ callStaff, response, setResponse ,setError, error }}>
            {children}
        </StaffContext.Provider>
    );
};

export const useStaffContext = (): StaffContextProps => {
    const context = useContext(StaffContext);
    if (context === undefined) {
        throw new Error('useStaffContext must be used within a StaffProvider');
    }
    return context;
};
