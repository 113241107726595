import React from 'react';
import styles from './snackbar.module.css';

interface SnackbarProps {
    message: string;
    type: 'green' | 'red';
}

const Snackbar: React.FC<SnackbarProps> = ({ message, type }) => {
    const snackbarClass = type === 'green' ? styles.green : styles.red;

    return (
        <div className={`${styles.snackbar} ${snackbarClass}`}>
            {message}
        </div>
    );
};

export default Snackbar;
