import React from 'react';
import styles from './header.module.css';

interface HeaderProps {
    title: string;
}

const Header: React.FC<HeaderProps> = ({ title }) => {
    return (
        <header>
            <h1 className={styles.title}>{title}</h1>
        </header>
    );
};

export default Header;
