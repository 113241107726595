import React from 'react';
import styles from './body.module.css';
import backgroundImage from '../../assets/images/background.png';

interface BodyProps {
    children: React.ReactNode;
}

const Body: React.FC<BodyProps> = ({ children }) => {
    return (
        <div
            className={styles.body}
            style={{ backgroundImage: `url(${backgroundImage})` }}
        >
            <div className={styles.content}>
                {children}
            </div>
        </div>
    );
};

export default Body;
