import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import App, { GeolocationContext } from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { StaffProvider } from "./context/StaffContext";
import Error from "./pages/Error";

// @ts-ignore
const GeolocationWrapper = ({ children }) => {
    const navigate = useNavigate();
    const [coords, setCoords] = useState({ latitude: 0, longitude: 0 });

    useEffect(() => {
        if (!navigator.geolocation) {
            navigate('/error', { state: { message: 'Geolocation is not supported by this browser.' } });
            return;
        }

        navigator.geolocation.getCurrentPosition(
            (position) => {
                console.log('Got geolocation:', position.coords);
                setCoords({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
            },
            (error) => {
                console.error('Error getting location:', error.message);
                navigate('/error', { state: { message: 'Error getting location: ' + error.message } });
            },
            {
                timeout: 10000, // 10 seconds
                maximumAge: 0,
                enableHighAccuracy: true
            }
        );
    }, []);

    return (
        <GeolocationContext.Provider value={coords}>
            {children}
        </GeolocationContext.Provider>
    );
};

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <StaffProvider>
                <GeolocationWrapper>
                    <Routes>
                        <Route path="call-staff/:tableId/:hospitalityId" element={<App />} />
                        <Route path="error" element={<Error />} />
                    </Routes>
                </GeolocationWrapper>
            </StaffProvider>
        </BrowserRouter>
    </React.StrictMode>
);

serviceWorkerRegistration.unregister();
reportWebVitals();
